<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row class="py-2">
          <v-col cols="12">
            <v-select :items="services" filled label="Послуга"
                      hide-details
                      v-model="service_id"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="service_id ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="6">
            <date-component v-model="date_start" req label="Дата початку" :class_="date_start ? '' : 'req-star'"/>
          </v-col>
          <v-col cols="6">
            <date-component v-model="date_end" label="Дата закінчення"/>
          </v-col>
          <v-col cols="12">
              <v-switch v-model="by_season" hide-details class="mt-0"
                        color="success"
                        :label="by_season ? 'Сезонна послуга (увімкнена)' : 'Сезонна послуга (вимкнена)'"
              />
          </v-col>
          <v-col cols="6">
              <v-text-field v-model="month_start" filled
                            :disabled="!by_season"
                            label="№ місяця початку"
                            hide-details color="grey"
                            :class="!by_season ? '' : month_start ? '' : 'req-star'"
                            v-integer
              />
          </v-col>
          <v-col cols="6">
              <v-text-field v-model="month_end" filled
                            label="№ місяць завершення"
                            :disabled="!by_season"
                            hide-details color="grey"
                            :class="!by_season ? '' : month_end ? '' : 'req-star'"
                            v-integer
              />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="updateService" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {ALERT_SHOW} from "@/store/actions/alert";
import {
  CREATE_FLAT_SERVICE,
  GET_ALL_WITH_BALANCE,
  UPDATE_FLAT_SERVICE,
  DELETE_FLAT_SERVICE,
  GET_ALL_SERVICE, GET_ALL_FLAT_TARIFF
} from "@/store/actions/flat_service";
import {SELECT_SERVICES_WITHOUT_ALL_SERVICE} from "@/store/actions/services";
import {QUESTION_SHOW} from "@/store/actions/question";
import modalComponentMixin from "@/mixins/modal_component"

const modalDeleteId = 'flat_service_member_modal_delete'

export default {
  name: "HWP_Modal_FlatService",
  mixins: [modalComponentMixin],
  data() {
    return {
      person_hash: this.item.person_hash,
      service_id: this.item.service_id,
      date_start: this.item.date_start,
      date_end: this.item.date_end,
      by_season: this.item.by_season || false,
      month_start: this.item.month_start || null,
      month_end: this.item.month_end || null,
      update: this.item.update || false
    }
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll',
    }),
  },
  methods: {
    ...mapActions({
      fetchServices: SELECT_SERVICES_WITHOUT_ALL_SERVICE,
      fetchFlatServices: GET_ALL_WITH_BALANCE
    }),
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.itemId = this.item.id
      this.person_hash = this.item.person_hash || null
      this.service_id = this.item.service_id
      this.date_start = this.item.date_start
      this.date_end = this.item.date_end
      this.by_season = this.item.by_season || false
      this.month_start = this.item.month_start
      this.month_end = this.item.month_end

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення послуги: ${this.getServiceName()}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    updateService() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Послуга, дата початку, тарифу мають бути заповнені',
          color: 'error lighten-1'
        })
        return undefined
      }
      if (this.by_season && (!this.month_start || !this.month_end)) {
          this.$store.commit(ALERT_SHOW, {
              text: 'Для правильного нарахування сезонної послуги - вкажіть № місяця початку та закінчення',
              color: 'error lighten-1'
          })
          return undefined
      }

      const payload = {
        person_hash: this.person_hash,
        service_id: this.service_id,
        date_start: this.date_start,
        date_end: this.date_end,
        by_season: this.by_season,
        month_start: this.month_start,
        month_end: this.month_end
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_FLAT_SERVICE, payload)
            .then(el => {
              if (el) {
                this.$store.dispatch(GET_ALL_WITH_BALANCE, this.person_hash)
                    .then(() => {
                      this.closeModal("fetch")
                    })
              }
            })
      } else {
        payload['id'] = this.itemId

        this.$store.dispatch(UPDATE_FLAT_SERVICE, payload)
            .then(el => {
              if (el) {
                this.$store.dispatch(GET_ALL_WITH_BALANCE, this.person_hash)
                    .then(() => {
                      this.closeModal("fetch")
                    })
              }
            })
      }
    },
    getServiceName() {
      if (this.service_id) {
        const ind = this.services.find(item => item.value === this.service_id)
        if (ind) {
          return ind.text
        }
      }
      return ''
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.person_hash = payload.person_hash
              this.service_id = payload.service_id
              this.date_start = payload.date_start
              this.date_end = payload.date_end
              this.by_season = payload.by_season
              this.month_start = payload.month_start
              this.month_end = payload.month_end
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_FLAT_SERVICE, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.$store.dispatch(GET_ALL_WITH_BALANCE, this.person_hash)
                          this.$store.dispatch(GET_ALL_SERVICE, this.person_hash)
                          this.$store.dispatch(GET_ALL_FLAT_TARIFF, this.person_hash)
                          this.closeModal("fetch")
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  created() {
    this.fetchServices()
  }
}
</script>